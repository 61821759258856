import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import Preloader from '../../Components/Preloader/index';
import EventUpLanDevelopment from '../../Components/Events/Index13';
import EventUpGestaltLan from '../../Components/Events/Index14';


const UpcommingEvents = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simulate loading process (replace with your actual loading logic)
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      {isLoading ? <Preloader /> : <></>}
      <Box
        sx={{
          textAlign: 'center',
          fontWeight: 600,
          fontSize: '50px',
          mb: 2,
          mt: 2,
        }}
      >
        <h1>Upcomming Events</h1>
      </Box>
      <EventUpLanDevelopment />
      <EventUpGestaltLan />
    </div>
  );
};

export default UpcommingEvents;
