import React, { useState } from 'react';
import Grid from '@mui/joy/Grid';
import faqData from './faq';


const FAQItem = ({ faq, index, toggleFAQ, activeIndex }) => {
    return (
        <Grid


        >
            <div className="border-b border-gray-200 ">
                <button
                    className="w-full text-left px-4 py-2 flex justify-between items-center focus:outline-none "
                    onClick={() => toggleFAQ(index)}
                >

                    <span className="text-lg font-semibold">{faq.question}</span>
                    <span className={`transform transition-transform duration-300 ${activeIndex === index ? 'rotate-180' : ''}`}>
                        ▼
                    </span>
                </button>
                <div
                    className={`overflow-hidden  duration-300 ${activeIndex === index ? 'max-h-100' : 'max-h-0'
                        }`}
                >
                    {faq.answers.map((answer, i) => (
                        <p key={i} className="px-4 py-2 text-gray-600 text-xs"> <div dangerouslySetInnerHTML={{ __html: answer }} /></p>
                    ))}
                </div>

            </div>
        </Grid>
    );
};

const ForParents = () => {
    const [activeIndex, setActiveIndex] = useState(null);

    const toggleFAQ = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    return (
        <div className="m-5">
            <div className='mx-5'>
                <Grid
                    container
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItem: 'center',
                    }}
                >
                    <Grid
                        item
                        lg={3}
                        md={12}
                        sm={12}
                        xs={12}
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItem: 'center',
                            border: '1px solid #88888d',
                            padding: '20px',
                            backgroundColor: '#eaeaed',
                            borderRadius: '10px',
                            margin: '10px',
                        }}

                    >
                        <iframe
                            width="100%"
                            height="300"
                            src="https://www.youtube.com/embed/Lk4qs8jGN4U?si=E8rMSEDnFfU7dpEy"
                            title="press media new 1"
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            referrerpolicy="strict-origin-when-cross-origin"
                            allowfullscreen
                        ></iframe>

                    </Grid>
                    <Grid
                        item
                        lg={3}
                        md={12}
                        sm={12}
                        xs={12}
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItem: 'center',
                            border: '1px solid #88888d',
                            padding: '20px',
                            backgroundColor: '#eaeaed',
                            borderRadius: '10px',
                            margin: '10px',
                        }}
                    >
                        <iframe
                            width="100%"
                            height="300"
                            src="https://www.youtube.com/embed/UofCqfONJbA?si=IGzOaKnAO3YA6kXF&amp;start=1"
                            title="YouTube video "
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            referrerpolicy="strict-origin-when-cross-origin"
                            allowfullscreen>

                        </iframe>
                    </Grid>
                    <Grid
                        item
                        lg={3}
                        md={12}
                        sm={12}
                        xs={12}
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItem: 'center',
                            border: '1px solid #88888d',
                            padding: '20px',
                            backgroundColor: '#eaeaed',
                            borderRadius: '10px',
                            margin: '10px',
                        }}
                    >
                        <iframe
                            width="100%"
                            height="300"
                            src="https://www.youtube.com/embed/0bEMB19dDvs?si=972NNgvNSqRwd3MH" 
                            title="YouTube video "
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            referrerpolicy="strict-origin-when-cross-origin"
                            allowfullscreen>

                        </iframe>
                    </Grid>
                </Grid>

            </div>
            <div>

            </div>

            <h2 className="text-2xl font-bold text-center m-6">Frequently Asked Questions</h2>
            <div className="bg-white shadow-md rounded-lg ">
                {faqData.map((faq, index) => (
                    <FAQItem
                        key={index}
                        faq={faq}
                        index={index}
                        toggleFAQ={toggleFAQ}
                        activeIndex={activeIndex}

                    />
                ))}
            </div>
        </div>
    );
};

export default ForParents;
