import React, { useEffect, useState, useRef } from 'react';
import { withbaby } from '../../config/Images/Images';
import TextField from '@mui/material/TextField';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import {
  Grid,
  Paper,
  Box,
  Button,
  Typography,
  FormHelperText,
} from '@mui/material';
import axios from 'axios';
import Swal from 'sweetalert2';
import './style.css';

const NeedJoin = () => {
  const form = useRef();

  const [formData, setFormData] = useState({
    name: '',
    emailAddress: '',
    gender: '',
    contactNo: '',
    address: '',
  });

  const [formErrors, setFormErrors] = useState({
    name: false,
    emailAddress: false,
    gender: false,
    contactNo: false,
    address: false,
  });

  const handleChange = (field) => (event) => {
    setFormData({ ...formData, [field]: event.target.value });
    setFormErrors({ ...formErrors, [field]: false });
  };

  const validateForm = () => {
    const errors = {
      name: !formData.name.trim(),
      emailAddress: !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.emailAddress),
      gender: !formData.gender,
      contactNo: !/^\d{10}$/.test(formData.contactNo),
      address: !formData.address.trim(),
    };
    setFormErrors(errors);
    return !Object.values(errors).some(Boolean);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    try {
      const response = await axios.post(
        'https://namasthe-server.onrender.com/api/send-report',
        formData
      );

      Swal.fire({
        icon: response.status === 200 ? 'success' : 'error',
        title: response.status === 200 ? 'Email Successfully Send!' : 'Error',
        toast: true,
        position: 'top-end',
        iconColor: 'white',
        customClass: { popup: 'colored-toast' },
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: () =>
          setFormData({
            name: '',
            emailAddress: '',
            gender: '',
            contactNo: '',
            address: '',
          }),
      });
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        toast: true,
        position: 'top-end',
        iconColor: 'white',
        customClass: { popup: 'colored-toast' },
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
      });
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Typography
        gutterBottom
        sx={{
          fontWeight: 600,
          textAlign: 'center',
          fontSize: '31px',
          fontFamily: 'Poppins, sans-serif',
          color: 'red',
          mb: 7,
          mt: 7,
        }}
      >
        If you are a parent of a child with autism or an adult with autism
        interested in joining our community,
        <div>apply here</div>
      </Typography>
      <Box
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        <Paper elevation={0} sx={{ width: '45rem', padding: '1rem' }}>
          <form ref={form} onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              {['name', 'address', 'emailAddress', 'contactNo'].map(
                (field, index) => (
                  <React.Fragment key={field}>
                    <Grid item xs={12} md={5}>
                      <label className="label">
                        {field.charAt(0).toUpperCase() + field.slice(1)}
                      </label>
                    </Grid>
                    <Grid item xs={12} md={7}>
                      <TextField
                        id={`outlined-${field}`}
                        label={field.charAt(0).toUpperCase() + field.slice(1)}
                        variant="outlined"
                        sx={{ width: '100%' }}
                        value={formData[field]}
                        onChange={handleChange(field)}
                        error={formErrors[field]}
                        helperText={
                          formErrors[field]
                            ? `${
                                field.charAt(0).toUpperCase() + field.slice(1)
                              } is required`
                            : ''
                        }
                      />
                    </Grid>
                  </React.Fragment>
                )
              )}
              <Grid item xs={12} md={5}>
                <label className="label">Gender</label>
              </Grid>
              <Grid item xs={12} md={7}>
                <RadioGroup
                  row
                  value={formData.gender}
                  onChange={handleChange('gender')}
                >
                  <FormControlLabel
                    value="Female"
                    control={<Radio />}
                    label="Female"
                  />
                  <FormControlLabel
                    value="Male"
                    control={<Radio />}
                    label="Male"
                  />
                </RadioGroup>
                {formErrors.gender && (
                  <FormHelperText error>Gender is required</FormHelperText>
                )}
              </Grid>
              <Box className="label">
                <Button
                  variant="contained"
                  color="success"
                  sx={{ fontSize: '20px', ml: 2, mt: 4, borderRadius: '10px' }}
                  type="submit"
                >
                  Send
                </Button>
              </Box>
            </Grid>
          </form>
        </Paper>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'center', mr: 10 }}>
        <img src={withbaby} alt="With Baby" style={{ width: '550px' }} />
      </Box>
      <br />
    </>
  );
};

export default NeedJoin;
