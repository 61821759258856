import React, { useState, useRef, useEffect } from 'react';
import { Paper, Grid, Box, Typography } from '@mui/material';
import { multisite_visit_img1, multisite_visit_img2, multisite_visit_img3, multisite_visit_img4, } from '../../config/Images/Images';
import { CarouselNormalEvents } from '../CarouselNormalEvents';
import { useSpring, animated } from 'react-spring';

const Multisite = () => {

  const slideImages = [
    {

      image: multisite_visit_img1,
    },
    {
      image: multisite_visit_img2,
    },
    {
      image: multisite_visit_img3,
    },
    {
      image: multisite_visit_img4,
    }
  ];
  const [isVisible, setVisible] = useState(false);
  const ref = useRef(null);

  const props = useSpring({
    opacity: isVisible ? 1 : 0,
    transform: isVisible ? 'translateX(0)' : 'translateX(-50px)',
  });

  const handleScroll = () => {
    const top = ref.current.getBoundingClientRect().top;
    const isVisible = top < window.innerHeight * 0.75;

    setVisible(isVisible);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const AnimatedPaper = animated(Paper);
  return (
    <div ref={ref}>
      <AnimatedPaper
        style={props}
        elevation={0}
        sx={{
          background: 'rgb(170, 122, 255)',
          // marginLeft: {
          //   xl: '10rem',
          //   lg: '9rem',
          //   md: '6rem',
          //   sm: '4rem',
          //   xs: '1px',
          // },
          marginRight: {
            xl: '10rem',
            lg: '9rem',
            md: '6rem',
            sm: '4rem',
            xs: '1px',
          },
          // borderTopLeftRadius: '40px',
          // borderBottomLeftRadius: '40px',
          borderTopRightRadius: '40px',
          borderBottomRightRadius: '40px',
          mb: 7,
          paddingTop: '20px ',
          paddingBottom: '20px',
          paddingLeft: {
            xl: '20px ',
            lg: '20px',
            md: '15px',
            sm: '12px',
            xs: '6px',
          },
          paddingRight: {
            xl: '20px ',
            lg: '20px',
            md: '15px',
            sm: '12px',
            xs: '6px',
          },
        }}
      >
        <Grid
          container
          spacing={2}
          sx={{
            paddingRight: {
              xl: '2rem',
              lg: '2rem',
              md: '2rem',
              sm: '1rem',
              xs: '0rem',
            },
            paddingTop: '2rem',
            paddingBottom: '2rem',
          }}
        >
          <Grid item xs={12} lg={6} md={12} sm={12} xl={6}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                height: '100%',
              }}
            >
              <Typography
                sx={{
                  fontWeight: 500,
                  fontSize: {
                    xl: '52px',
                    lg: '47px',
                    md: '47px',
                    sm: '30px',
                    xs: '25px',
                  },
                  textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',
                  paddingLeft: {
                    xl: '3rem',
                    lg: '3rem',
                    md: '2rem',
                    sm: '2rem',
                    xs: '0rem',
                  },
                  textAlign: {
                    xs: 'center',
                    xl: 'start',
                    lg: 'start',
                    md: 'start',
                    sm: 'start',
                  },
                  fontFamily: 'Poppins, sans-serif',
                }}
              >
                Multisite Visit 2024
              </Typography>
              <Typography
                sx={{
                  fontWeight: 500,
                  fontSize: {
                    xl: '21px',
                    lg: '21px',
                    md: '21px',
                    sm: '18px',
                    xs: '15px',
                  },
                  paddingLeft: {
                    xl: '3rem',
                    lg: '3rem',
                    md: '2rem',
                    sm: '2rem',
                    xs: '0rem',
                  },
                  textAlign: {
                    xs: 'center',
                    xl: 'start',
                    lg: 'start',
                    md: 'start',
                    sm: 'start',
                  },
                  fontFamily: 'Poppins, sans-serif',
                }}
              >
              The Multisite Visit of the NAMASTE Project 2024 took place on the 21st, 22nd, and 23rd of May at the Regal Reseau Hotel in Negombo. Participants included NAMASTE members from the UK, India, Nepal, with Sri Lanka as the host country. Representatives from the Sri Lanka College of Paediatricians and the Family Health Bureau were present to support the occasion, as they have been consistent supporters throughout the project.
              </Typography>
              <br />
              <Typography
                sx={{
                  fontWeight: 500,
                  fontSize: {
                    xl: '21px',
                    lg: '21px',
                    md: '21px',
                    sm: '18px',
                    xs: '15px',
                  },
                  paddingLeft: {
                    xl: '3rem',
                    lg: '3rem',
                    md: '2rem',
                    sm: '2rem',
                    xs: '0rem',
                  },

                  textAlign: {
                    xs: 'center',
                    xl: 'start',
                    lg: 'start',
                    md: 'start',
                    sm: 'start',
                  },
                  fontFamily: 'Poppins, sans-serif',
                }}
              >
          

During this visit, discussions covered topics such as detection workflows, intervention workstreams, capacity building, community engagement, and involvement. Additionally, the team held a "NAMASTE Got Talent" show to showcase the talents of young researchers and highlight their rich cultural perspectives. This marked a successful three-day workshop hosted by Sri Lanka, led by Dr. Dilini Vipulaguna and Dr. Asiri Hemamalage.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} lg={6} md={12} sm={12} xl={6}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.3)',
                mt: { lg: '7rem', xl: '0', md: '0', xs: '0' },
                mb: { lg: '7rem', xl: '0', md: '0', xs: '0' },
              }}
            >
              {/* <img src={event6} alt={event6} width="100%" /> */}
              <CarouselNormalEvents slideImages={slideImages}/>
            </Box>
          </Grid>
        </Grid>
      </AnimatedPaper>
    </div>
  );
};

export default Multisite;
