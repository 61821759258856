import React, { useEffect, useState } from 'react';
import Grid from '@mui/joy/Grid';
import Container from '@mui/material/Container';
import Preloader from './../../Components/Preloader/index';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import { Link } from 'react-router-dom';
import { forParents, commonResources } from "../../config/Images/Images";

const Resources = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simulate loading process (replace with your actual loading logic)
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);
  return (

    <Container my={3}>
      {isLoading ? <Preloader /> : <></>}
      {/* <Box
        sx={{
          textAlign: 'center',
          fontWeight: 600,
          fontSize: '50px',
          mb: 2,
          mt: 2,
        }}
      >
        <h1>Autism Awareness 2024</h1>
      </Box> */}
      <Grid >
        <div style={{ marginLeft: '5rem', marginRight: '5rem', marginBottom: '3rem' }}>
          <Grid
            container
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItem: 'center',
            }}
          >
            <Grid
              item
              lg={6}
              md={6}
              sm={12}
              xs={12}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItem: 'center',
              }}
            >
              <Link to="/for-parents" style={{ textDecoration: 'none' }}>
                <Card
                  sx={{
                    // maxWidth: 345,
                    mt: 6,
                    borderRadius: 10,
                    height: '15rem',
                    width: '20rem',

                    border: '2px solid purple',
                    transition: 'border-color 0.3s ease',
                    '&:hover': {
                      borderColor: 'purple',
                      cursor: 'pointer',
                      border: '3px solid purple',
                      backgroundColor: '#e2fff2'
                    },
                  }}
                >
                  <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    height="100%"
                  >
                    <Grid
                      item
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItem: 'center',
                      }}
                    >
                      <img src={forParents} alt="" width="50%" height="50%" />
                    </Grid>
                    <Grid item>
                      <Typography
                        variant="body1"
                        align="center"
                        mt={1}
                        sx={{
                          fontWeight: 'bold',
                          color: '#769863',
                          fontFamily: 'Poppins, sans-serif',
                          fontSize: '20px'
                        }}
                      >
                        Parental resources
                      </Typography>
                      <Typography
                        variant="body1"
                        align="center"
                        mt={1}
                        sx={{
                          fontWeight: '100',
                          color: '#bdbbbb',
                          fontFamily: 'Poppins, sans-serif',
                          padding: '5px'
                        }}
                      >

                      </Typography>
                    </Grid>
                  </Grid>
                </Card>
              </Link>
            </Grid>
            <Grid
              item
              lg={6}
              md={6}
              sm={12}
              xs={12}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItem: 'center',
              }}
            >
              <Link to="/common-resources" style={{ textDecoration: 'none' }}>
                <Card
                  sx={{
                    // maxWidth: 345,
                    mt: 6,
                    borderRadius: 10,
                    height: '15rem',
                    width: '20rem',

                    border: '2px solid purple',
                    transition: 'border-color 0.3s ease',
                    '&:hover': {
                      borderColor: 'purple',
                      cursor: 'pointer',
                      border: '3px solid purple',
                      backgroundColor: '#e2fff2'
                    },
                  }}
                >
                  <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    height="100%"
                  >
                    <Grid
                      item
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItem: 'center',
                      }}
                    >
                      <img src={commonResources} alt="" width="50%" height="50%" />
                    </Grid>
                    <Grid item>
                      <Typography
                        variant="body1"
                        align="center"
                        mt={1}
                        color="purple"
                        sx={{
                          fontWeight: '900',
                          color: '#769863',
                          fontFamily: 'Poppins, sans-serif',
                          fontSize: '20px',

                        }}
                      >
                        Common Resources
                      </Typography>

                    </Grid>
                  </Grid>
                </Card>
              </Link>
            </Grid>
          </Grid>
        </div>
        {/* <Grid
          lg={12}
          md={12}
          sm={12}
          xs={12}
          sx={{
            border: '1px solid #88888d',
            padding: '30px',
            backgroundColor: '#eaeaed',
            borderRadius: '40px',
          }}
        >
          <iframe
            width="100%"
            height="550"
            src="https://www.youtube.com/embed/qwRDt9s0jD0"
            title="press media new 1"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
          ></iframe>
        </Grid>
        <Grid
          lg={12}
          md={12}
          sm={12}
          xs={12}
          my={5}
          sx={{
            border: '1px solid #88888d',
            padding: '30px',
            backgroundColor: '#eaeaed',
            borderRadius: '40px',
          }}
        >
          <iframe
            width="100%"
            height="550"
            src="https://www.youtube.com/embed/S7Uno3Olzf8"
            title="dr kosala"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
          ></iframe>
        </Grid>
        <Grid
          lg={12}
          md={12}
          sm={12}
          xs={12}
          mb={5}
          sx={{
            border: '1px solid #88888d',
            padding: '30px',
            backgroundColor: '#eaeaed',
            borderRadius: '40px',
          }}
        > 
          <iframe
            width="100%"
            height="550"
            src="https://www.youtube.com/embed/_YPVfP2gfnk"
            title="dr asiri"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
          ></iframe>
        </Grid>
        <Grid
          lg={12}
          md={12}
          sm={12}
          xs={12}
          mb={5}
          sx={{
            border: '1px solid #88888d',
            padding: '30px',
            backgroundColor: '#eaeaed',
            borderRadius: '40px',
          }}
        >
          <iframe
            width="100%"
            height="550"
            src="https://www.youtube.com/embed/IR7e00B7dnc"
            title="dr dilini"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
          ></iframe>
        </Grid>

        <Grid
          lg={12}
          md={12}
          sm={12}
          xs={12}
          mb={5}
          sx={{
            border: '1px solid #88888d',
            padding: '30px',
            backgroundColor: '#eaeaed',
            borderRadius: '40px',
          }}
        >
          <iframe
            width="100%"
            height="550"
            src="https://www.youtube.com/embed/7jm4Yj8-PcY"
            title="ලොව පුරා මව්පියන්ට දුක්දෙන ඔටිසම්...  දරුවා වෙනුවෙන් කළ යුතු හොදම දේ දැන සිටියාද?"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
          ></iframe>
        </Grid>

        <Grid
          lg={12}
          md={12}
          sm={12}
          xs={12}
          mb={5}
          sx={{
            border: '1px solid #88888d',
            padding: '30px',
            backgroundColor: '#eaeaed',
            borderRadius: '40px',
          }}
        >
          <iframe
            width="100%"
            height="550"
            src="https://www.youtube.com/embed/GUdOpoNyXhs"
            title="Ms. Rathna Lalani Jayakodi Video"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
          ></iframe>
        </Grid> */}
      </Grid>
    </Container>
  );
};

export default Resources;
