import React, { useState, useRef, useEffect } from 'react';
import { Paper, Grid, Box, Typography } from '@mui/material';
import { event_euld } from '../../config/Images/Images';
import { useSpring, animated } from 'react-spring';
import { Button } from '@material-tailwind/react';

const EventUpLanDevelopment = () => {
  const ref = useRef();
  const [isVisible, setIsVisible] = useState(false);
  const [animatedProps, setAnimatedProps] = useSpring(() => ({
    opacity: 0,
    transform: isVisible ? 'translateX(0)' : 'translateX(-50px)',
    from: { opacity: 0, transform: 'translateX(-100%)' },
    to: { opacity: 1, transform: 'translateX(0)' },
    config: { duration: 1000 },
  }));

  useEffect(() => {
    if (ref.current) {
      setIsVisible(true);
    }
  }, [setAnimatedProps]);

  const AnimatedPaper = animated(Paper);
  return (
    <div ref={ref}>
      <AnimatedPaper
        style={animatedProps}
        elevation={0}
        sx={{
          background: 'rgb(170, 122, 255)',
          marginRight: {
            xl: '10rem',
            lg: '9rem',
            md: '6rem',
            sm: '4rem',
            xs: '1px',
          },
          borderTopRightRadius: '40px',
          borderBottomRightRadius: '40px',
          mb: 7,
          paddingTop: '20px ',
          paddingBottom: '20px',
          paddingLeft: {
            xl: '20px ',
            lg: '20px',
            md: '15px',
            sm: '12px',
            xs: '6px',
          },
          paddingRight: {
            xl: '20px ',
            lg: '20px',
            md: '15px',
            sm: '12px',
            xs: '6px',
          },
        }}
      >
        <Grid
          container
          spacing={2}
          sx={{
            paddingLeft: {
              xl: '2rem',
              lg: '2rem',
              md: '2rem',
              sm: '1rem',
              xs: '0rem',
            },
            paddingTop: '2rem',
            paddingBottom: '2rem',
          }}
        >
          <Grid
            item
            xs={12}
            lg={6}
            md={12}
            sm={12}
            xl={6}
            sx={{ order: { md: 2, xs: 2, sm: 2 } }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.3)',
                mt: { lg: '7rem', xl: '0', md: '0', xs: '0' },
                mb: { lg: '7rem', xl: '0', md: '0', xs: '0' },
              }}
            >
              <img src={event_euld} alt={event_euld} width="100%" />
            </Box>
          </Grid>{' '}
          <Grid
            item
            xs={12}
            lg={6}
            md={12}
            sm={12}
            xl={6}
            sx={{ order: { md: 1, xs: 1, sm: 1 } }}
          >
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: {
                  xl: '52px',
                  lg: '47px',
                  md: '47px',
                  sm: '30px',
                  xs: '25px',
                },
                textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',
                paddingLeft: {
                  xl: '3rem',
                  lg: '3rem',
                  md: '2rem',
                  sm: '2rem',
                  xs: '0rem',
                },
                textAlign: {
                  xs: 'center',
                  xl: 'start',
                  lg: 'start',
                  md: 'start',
                  sm: 'start',
                },
                fontFamily: 'Poppins, sans-serif',
              }}
            >
              {/* Sample heading LanDevelopment */}
            </Typography>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: {
                  xl: '21px',
                  lg: '21px',
                  md: '21px',
                  sm: '18px',
                  xs: '15px',
                },
                paddingLeft: {
                  xl: '3rem',
                  lg: '3rem',
                  md: '2rem',
                  sm: '2rem',
                  xs: '0rem',
                },
                textAlign: {
                  xs: 'center',
                  xl: 'start',
                  lg: 'start',
                  md: 'start',
                  sm: 'start',
                },
                fontFamily: 'Poppins, sans-serif',
              }}
            >
              Are you a parent eager to understand and support your child's
              unique way of communicating, this workshop is designed for you.
              This session is designed to help parents recognize and nurture
              their child's communication style through practical strategies and
              expert guidance.
            </Typography>
            <br />
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: {
                  xl: '21px',
                  lg: '21px',
                  md: '21px',
                  sm: '18px',
                  xs: '15px',
                },
                paddingLeft: {
                  xl: '3rem',
                  lg: '3rem',
                  md: '2rem',
                  sm: '2rem',
                  xs: '0rem',
                },
                // paddingRight: "2rem",
                textAlign: {
                  xs: 'center',
                  xl: 'start',
                  lg: 'start',
                  md: 'start',
                  sm: 'start',
                },
                fontFamily: 'Poppins, sans-serif',
              }}
            >
              Through this workshop, you will learn;
              <br /> -Introduction: An Update about Autism and the Double
              Empathy problem in 2024 The history of Gestalt Language
              Development and its relevance (Research base & General Intro)
              <br /> -Types of language development
              <br /> -How to know if our children might be gestalt language
              processors?
              <br /> -Characteristics of Gestalt Language Development & Natural
              Language Acquisition
              <br />
              -Stages/Strategies
              <br /> -Opportunity to discuss a case Example Strategies for GLPs
              at home And also you will get an opportunity to share your
              personal journeys (parents are welcome to submit videos of their
              children if they would like to) Don't miss out on this chance to
              empower your child's language development journey! Reserve your
              seat today.
            </Typography>
            <br />
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: {
                  xl: '21px',
                  lg: '21px',
                  md: '21px',
                  sm: '18px',
                  xs: '15px',
                },
                paddingLeft: {
                  xl: '3rem',
                  lg: '3rem',
                  md: '2rem',
                  sm: '2rem',
                  xs: '0rem',
                },
                // paddingRight: "2rem",
                textAlign: {
                  xs: 'center',
                  xl: 'start',
                  lg: 'start',
                  md: 'start',
                  sm: 'start',
                },
                fontFamily: 'Poppins, sans-serif',
              }}
            >
              Registration Link:
              <br />
              <a
                href="https://docs.google.com/forms/d/e/1FAIpQLSfblbu4P-lvkrKrBmEjfP2qoScIXB4YTK_v5fQ_ShG1PqkwPw/viewform"
                target="_blank"
                rel="noreferrer"
              >
                <Button
                  size="sm"
                  color="green"
                  style={{ backgroundColor: 'green', marginTop: '10px' }}
                >
                  Click here
                </Button>
              </a>
            </Typography>
          </Grid>{' '}
        </Grid>
      </AnimatedPaper>
    </div>
  );
};

export default EventUpLanDevelopment;
