import React, { useEffect, useState } from 'react';
//import EventRightPaper2 from '../../Components/Events/Index3';
import { Box } from '@mui/material';
import Preloader from '../../Components/Preloader/index';
import EventLotusTower from './../../Components/Events/Index5';
import EventSACS from '../../Components/Events/Index6';
import EventCST from '../../Components/Events/Index7';
import EventAutismAwareness from '../../Components/Events/Index10';
import EventIRD from '../../Components/Events/Index11';
import EventVABS from '../../Components/Events/Index12';
import { Button } from '@material-tailwind/react';
import { Link } from 'react-router-dom';
import AacTraining from '../../Components/Events/aac_training';
import EventAdos from '../../Components/Events/event_ados';
import SlpcCollabarate from '../../Components/Events/slpc_collabarate';
import Multisite from '../../Components/Events/multisite';
import GestaltLanguage from '../../Components/Events/gestalt_language';
import IntegratingNamsthe from '../../Components/Events/integrating_namsthe';

const NormalEvents = () => {

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simulate loading process (replace with your actual loading logic)
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      {isLoading ? <Preloader /> : <></>}
      <Box
        sx={{
          textAlign: 'center',
          fontWeight: 600,
          fontSize: '50px',
          mb: 2,
          mt: 2,
        }}
      >
        <h1>Events</h1>
        <Link to="/upcomming-events" style={{ textDecoration: 'none' }}>
          <Button color="green" style={{ backgroundColor: '#769863' }}>
            upcomming events
          </Button>
        </Link>
      </Box>
      <IntegratingNamsthe />
      <EventLotusTower />
      <GestaltLanguage />
      <Multisite />
      <EventIRD />
      <EventVABS />
      <EventSACS />
      <EventCST />
      <EventAdos />
      <AacTraining />
      <SlpcCollabarate />



      {/* 
      <EventLotusTower />
      <EventAutismAwareness /> */}

      {/* <EventRightPaper2 /> */}
      {/* <EventLeftPaper2 /> */}
      {/* <EventADOS /> */}
      {/* <EventSLCP /> */}
    </div>
  );
};

export default NormalEvents;
