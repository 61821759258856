import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { ThemeProvider } from "@material-tailwind/react";
import { BrowserRouter } from "react-router-dom";
import TagManager from "react-gtm-module";
const root = ReactDOM.createRoot(document.getElementById("root"));

const tagManagerArgs={
  gtmId:'GTM-NRDQ9Z2Z'
};

TagManager.initialize(tagManagerArgs);

root.render(
  <React.StrictMode>
    <ThemeProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ThemeProvider>
  </React.StrictMode>
);
