export const namastheLogo = require('../../Common/Assets/Images/NAMASTE_Curves-1 1.png');
export const namastheLogo2 = require('../../Common/Assets/Images/NAMASTE_Curves-1 2.png');
export const facebook = require('../../Common/Assets/Images/facebook.png');
export const instagram = require('../../Common/Assets/Images/instagram.png');
export const linkedin = require('../../Common/Assets/Images/linkedin.png');

// Carousel
export const Carousel1 = require('../../Common/Assets/Images/Property1.png');
export const Carousel2 = require('../../Common/Assets/Images/3rd picture.png');
export const Carousel3 = require('../../Common/Assets/Images/Property3.png');

export const card1 = require('../../Common/Assets/Images/Copy of About us (6) 4.png');

export const community1 = require('../../Common/Assets/Images/comminuty 1.png');
export const detection1 = require('../../Common/Assets/Images/detection 1.png');
export const engagement1 = require('../../Common/Assets/Images/engagement 1.png');
export const intervention1 = require('../../Common/Assets/Images/intervention 1.png');

export const group1 = require('../../Common/Assets/Images/Group.png');
export const group2 = require('../../Common/Assets/Images/Group2.png');

// Footer
export const Rectangle = require('../../Common/Assets/Images/Rectangle__1_-removebg-preview.png');
export const Rectangle1 = require('../../Common/Assets/Images/Rectangle__3_-removebg-preview.png');
export const Rectangle2 = require('../../Common/Assets/Images/Rectangle__4_-removebg-preview.png');
export const Rectangle3 = require('../../Common/Assets/Images/Rectangle__5_-removebg-preview.png');
export const Rectangle4 = require('../../Common/Assets/Images/Rectangle-removebg-preview.png');
export const Rectangle5 = require('../../Common/Assets/Images/FC1.png');
export const Rectangle6 = require('../../Common/Assets/Images/FC2.png');
export const Rectangle7 = require('../../Common/Assets/Images/FC3.png');

export const projectcard1 = require('../../Common/Assets/Images/Clip path group.png');
export const gif = require('../../Common/Assets/Images/Untitled design.gif');

export const aboutus = require('../../Common/Assets/Images/Copy of About us (17) 1.png');
export const aboutus1 = require('../../Common/Assets/Images/Copy of About us (17) 2.png');
export const aboutus2 = require('../../Common/Assets/Images/Copy of About us (17) 6.png');
export const email = require('../../Common/Assets/Images/email.png');

export const projectcard2 = require('../../Common/Assets/Images/card.png');
export const Team1 = require('../../Common/Assets/Images/team1.png');
export const Team2 = require('../../Common/Assets/Images/team2.png');
export const underconstruction = require('../../Common/Assets/Images/under-construction-2891888_1280.jpg');

// SL Team
//export const asiri = require('../../Common/Assets/Images/asiri.jpg');
//new asiri
export const asiri = require('../../Common/Assets/Images/sl-team-new/asiri.png');

export const raj = require('../../Common/Assets/Images/dr raj.jpg');
//export const chamika = require('../../Common/Assets/Images/chamika.jpg');
//new chamika
export const chamika = require('../../Common/Assets/Images/sl-team-new/chamika.png');

//export const hasuni = require('../../Common/Assets/Images/hasuni.jpg');
//new hasuni
export const hasuni = require('../../Common/Assets/Images/sl-team-new/hasuni.png');

//export const heshali = require('../../Common/Assets/Images/heshali.jpg');
//new heshali
export const heshali = require('../../Common/Assets/Images/sl-team-new/heshali.png');

//export const poornima = require('../../Common/Assets/Images/madu.jpg');
//new poornima
export const poornima = require('../../Common/Assets/Images/sl-team-new/poornima.png');

export const nethma = require('../../Common/Assets/Images/nethma.jpg');
//export const piumi = require('../../Common/Assets/Images/piyumi.jpg');
//new piumi
export const piumi = require('../../Common/Assets/Images/sl-team-new/piumi.png');

//export const rasika = require('../../Common/Assets/Images/rasika.jpg');
//new rasika
export const rasika = require('../../Common/Assets/Images/sl-team-new/rasika.png');

//export const sakuni = require('../../Common/Assets/Images/sakuni.jpg');
//new sakuni
export const sakuni = require('../../Common/Assets/Images/sl-team-new/sakuni.png');

//export const shashini = require('../../Common/Assets/Images/professional photo.jpg');
//new shashini
export const shashini = require('../../Common/Assets/Images/sl-team-new/shashini.png');

//export const Shehara = require('../../Common/Assets/Images/shehara.jpg');
//new shehara
export const Shehara = require('../../Common/Assets/Images/sl-team-new/shehara.png');

//export const yas = require('../../Common/Assets/Images/yashoda.jpg');
//new yas
export const yas = require('../../Common/Assets/Images/sl-team-new/yas.png');

export const abi = require('../../Common/Assets/Images/abi.jpg');
//export const venura = require('../../Common/Assets/Images/venura.jpg');
//new venura
export const venura = require('../../Common/Assets/Images/sl-team-new/venura.png');

//export const vidusha = require('../../Common/Assets/Images/vidusha.jpg');
//new vidusha
export const vidusha = require('../../Common/Assets/Images/sl-team-new/vidusha.png');

//export const dilini = require('../../Common/Assets/Images/dr dilini.jpg');
//new dilini
export const dilini = require('../../Common/Assets/Images/sl-team-new/drdilini.png');

//export const ayendre = require('../../Common/Assets/Images/aysha-Untitled design (1).jpg');
//new ayendre
export const ayendre = require('../../Common/Assets/Images/sl-team-new/ayendre.png');

// ======new members==========
//export const jayamini = require('../../Common/Assets/Images/jayamini.png');
//new jayamini
export const jayamini = require('../../Common/Assets/Images/sl-team-new/jayamini.png');

//export const madushalini = require('../../Common/Assets/Images/madushalini.png');
//new madushalini
export const madushalini = require('../../Common/Assets/Images/sl-team-new/madushalini.png');

//export const anupi = require('../../Common/Assets/Images/anupi.png');
//new anupi
export const anupi = require('../../Common/Assets/Images/sl-team-new/anupi.png');

export const kushani = require('../../Common/Assets/Images/kushani.png');
//kushani

export const laptopimg = require('../../Common/Assets/Images/About us-1.png');
export const proobj = require('../../Common/Assets/Images/proobj.png');
export const withbaby = require('../../Common/Assets/Images/withbaby.png');

export const event1 = require('../../Common/Assets/Images/imag1.JPG');
export const event2 = require('../../Common/Assets/Images/img2.JPG');
export const event3 = require('../../Common/Assets/Images/img3.JPG');
export const event4 = require('../../Common/Assets/Images/event4.jpg');
//event lotus=event5
export const event_lotus_1 = require('../../Common/Assets/Images/normal-events-carousel/lotus1.webp');
export const event_lotus_2 = require('../../Common/Assets/Images/normal-events-carousel/lotus2.jpeg');

//event SACS=event6
export const event6 = require('../../Common/Assets/Images/event6.JPG');
//event CST
export const event_cst_1 = require('../../Common/Assets/Images/normal-events-carousel/CST1.JPG');
export const event_cst_2 = require('../../Common/Assets/Images/normal-events-carousel/CST2.JPG');

//event SACS
export const event_sacs_1 = require('../../Common/Assets/Images/normal-events-carousel/SACS1.JPG');
export const event_sacs_2 = require('../../Common/Assets/Images/normal-events-carousel/SACS2.JPG');

//event AutismAwareness
export const event_autism_awar_1 = require('../../Common/Assets/Images/normal-events-carousel/AutismAwar1.jpeg');
export const event_autism_awar_2 = require('../../Common/Assets/Images/normal-events-carousel/AutismAwar2.jpeg');
export const event_autism_awar_3 = require('../../Common/Assets/Images/normal-events-carousel/AutismAwar3.jpeg');

//event IRD
export const event_ird_1 = require('../../Common/Assets/Images/normal-events-carousel/IRD1.jpeg');

//event VABS
export const event_vabs_1 = require('../../Common/Assets/Images/normal-events-carousel/VABS_1.png');
export const event_vabs_2 = require('../../Common/Assets/Images/normal-events-carousel/VABS_2.jpg');

//events upcomming
export const event_euld = require('../../Common/Assets/Images/upcomming-events/EventUpLanDevelopment-1.jpeg');
export const event_eugl = require('../../Common/Assets/Images/upcomming-events/EventUpGestaltLan-2.jpeg');

export const family = require('../../Common/Assets/Images/withbaby.png');
export const contactus = require('../../Common/Assets/Images/contactus.png');

export const card01 = require('../../Common/Assets/Images/card1.png');
export const card02 = require('../../Common/Assets/Images/croprounded1.jpg');
// new
export const card03 = require('../../Common/Assets/Images/nulrundi-gunawardane.png');

export const gt1 = require('../../Common/Assets/Images/gt1.jpg');
export const gt2 = require('../../Common/Assets/Images/gt2.jpg');
export const gt3 = require('../../Common/Assets/Images/gt3.jpg');
export const gt4 = require('../../Common/Assets/Images/gt4.jpg');
export const gt5 = require('../../Common/Assets/Images/gt5.jpg');
export const gt6 = require('../../Common/Assets/Images/gt6.jpg');
export const gt7 = require('../../Common/Assets/Images/gt7.jpg');
export const gt8 = require('../../Common/Assets/Images/gt8.jpg');

export const project2 = require('../../Common/Assets/Images/project2.png');
export const project3 = require('../../Common/Assets/Images/project3.png');

export const project4 = require('../../Common/Assets/Images/Namasthe_Project_-_UI_ss_1_-removebg-preview.png');
export const project5 = require('../../Common/Assets/Images/Namasthe_Project_s-_UI-removebg-preview.png');

//resources
export const forParents = require('../../Common/Assets/Images/resources/for-parents.jpg');
export const commonResources = require('../../Common/Assets/Images/resources/common-resources.jpg');

//event-intergrating-namsthe
export const event_intergrate_img1 = require('../../Common/Assets/Images/events/intergrating-namsthe/DSC02536.jpg');
export const event_intergrate_img2 = require('../../Common/Assets/Images/events/intergrating-namsthe/DSC02549.jpg');
export const event_intergrate_img3 = require('../../Common/Assets/Images/events/intergrating-namsthe/DSC02573.jpg');
export const event_intergrate_img4 = require('../../Common/Assets/Images/events/intergrating-namsthe/DSC02606.jpg');
export const event_intergrate_img5 = require('../../Common/Assets/Images/events/intergrating-namsthe/DSC02630.jpg');

//gestalt-workshop
export const gestalt_workshop_img1 = require('../../Common/Assets/Images/events/gestalt-workshop/DSC02237.jpg');
export const gestalt_workshop_img2 = require('../../Common/Assets/Images/events/gestalt-workshop/DSC02288.jpg');
export const gestalt_workshop_img3 = require('../../Common/Assets/Images/events/gestalt-workshop/DSC02345-2.jpg');

//multisite-visit
export const multisite_visit_img1 = require('../../Common/Assets/Images/events/multisite-visit/DSC01340.jpg');
export const multisite_visit_img2 = require('../../Common/Assets/Images/events/multisite-visit/DSC01380.jpg');
export const multisite_visit_img3 = require('../../Common/Assets/Images/events/multisite-visit/DSC01398.jpg');
export const multisite_visit_img4 = require('../../Common/Assets/Images/events/multisite-visit/DSC01441.jpg');
