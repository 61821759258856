const faqData = [
    {
        question: "What are neurodevelopmental disorders ?",
        answers: [
            "Neurodevelopmental disorders (NDDs) represent a group of diseases characterized by an altered development of the Central Nervous System (CNS) that leads to the manifestation of brain dysfunctions later in life and that may be associated with neuropsychiatric problems or impaired motor function, learning, language or non-verbal communication.",
            "There are multiple neurodevelopmental conditions that can affect children and adolescents. The most common neurodevelopmental disorders are",
            "- Autism spectrum disorder",
            "- Attention-deficit/hyperactivity disorder (ADHD)",
            "- Dyslexia"
        ]
    },
    {
        question: "What is Autism?",
        answers: [
            "Description on ASD.",
            "- Prevalence Autism Spectrum Disorder (ASD) affects about 1 in 100 children globally, though the prevalence can vary significantly between studies and regions. In many low- and middle-income countries, the prevalence remains unknown.",
            "- Symptoms: ASD is characterized by challenges in social interaction and communication. Individuals may also exhibit atypical patterns of behavior, including difficulties with transitioning between activities, an intense focus on details, and unusual responses to sensory stimuli.",
            "- Characteristics: The abilities and needs of individuals with ASD vary widely and can change over time. Some autistic individuals can live independently, while others may have severe disabilities that require lifelong care and support. Autism can impact education, employment opportunities, and social relationships.<br><br> The exact causes of ASD are not fully understood, but it is believed to result from a combination of genetic and environmental factors. Extensive research has shown that vaccines, including the MMR (measles, mumps, and rubella) vaccine, do not cause autism.",

        ]
    },
    {
        question: "Key facts",
        answers: [
            "- About 1 in 100 children has autism.",
            "- Symptoms may appear early but are often diagnosed later.",
            "- Abilities and needs vary; some live independently, others need lifelong care.",
            "- Evidence-based interventions can improve communication and social skills.",
            "- Care requires community and societal actions for better accessibility and inclusivity."

        ]
    }, {
        question: "Comorbidities",
        answers: [
            "Individuals with ASD often experience co-occurring conditions such as epilepsy, depression, anxiety, and Attention Deficit Hyperactivity Disorder (ADHD). They may also struggle with behaviors like difficulty sleeping and self-injury. Intellectual functioning among autistic individuals can vary significantly, from profound impairment to superior levels..",
        ]
    }, {
        question: "Assessment and Care",
        answers: [
            "A broad range of interventions, starting from early childhood and extending throughout life, can optimize development and quality of life for those with autism. Timely access to evidence-based psychosocial interventions can significantly enhance communication and social interaction skills in children with autism. Comprehensive care involves collaboration across health, education, and social services sectors. Regular monitoring of child development is recommended to identify autism early and ensure appropriate interventions.",
        ]
    }, {
        question: "Education & support",
        answers: [
            " <Center><div><iframe width='300' height='200' src='https://www.youtube.com/embed/9sDmZl6ePM8?si=QTK1pgD-8YV2L3_3' title='YouTube video player' frameborder='0' allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share' referrerpolicy='strict-origin-when-cross-origin' allowfullscreen></iframe> <br> <iframe width='300' height='200' src='https://www.youtube.com/embed/8w9vdUxh-og?si=gW0JoKgk3Z_8-f45' title='YouTube video player' frameborder='0' allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share' referrerpolicy='strict-origin-when-cross-origin' allowfullscreen></iframe><br> <iframe width='300' height='200' src='https://www.youtube.com/embed/SECG8RdF6Wg?si=XBx3n42BfKSHZ4-4' title='YouTube video player' frameborder='0' allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share' referrerpolicy='strict-origin-when-cross-origin' allowfullscreen></iframe></div><center/>"
        ]
    },

];




export default faqData;

